import React, { useEffect, useContext, useRef } from "react";
import { OnlineStatusContext } from "../../customHooks/OnlineStatusContext";
import { Toast } from "primereact/toast";

function useNetworkStatusNotifier(): JSX.Element {
    const isOnline = useContext(OnlineStatusContext);

    const toast = useRef<Toast>(null);
    const lastNotificationRef = useRef<boolean | null | undefined>(null); // Tracks last notification state
    const isFirstRender = useRef<boolean>(true); // Tracks if it's the first render

    useEffect(() => {
        if (isFirstRender.current) {
            // Skip the first render
            isFirstRender.current = false;
            lastNotificationRef.current = isOnline; // Initialize lastNotificationRef
            return;
        }

        if (toast.current && isOnline !== lastNotificationRef.current) {
            lastNotificationRef.current = isOnline; // Update the last state

            if (isOnline) {
                toast.current.show({
                    severity: "success",
                    summary: "Online",
                    detail: "You are back online!",
                    life: 10000,
                });
            } else {
                toast.current.show({
                    severity: "warn",
                    summary: "Offline",
                    detail: "You are offline. Some features may not be available.",
                    life: 10000,
                });
            }
        }
    }, [toast,isOnline]);

    return <Toast ref={toast} />;
}

export default useNetworkStatusNotifier;
